import { RouteInfo } from "./sidebar.metadata";
export const ROUTES: RouteInfo[] = [
  {
    path: "/admin/dashboard/main",
    title: "Dashboard",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "dashboard",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["SuperAdministrator"],
    submenu: [],
  }, 
  {
    path: "",
    title: "Customer Enquiry",
    moduleName: "enquiry",
    iconType: "material-icons-two-tone",
    icon: "contact_support",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["SuperAdministrator"],
    submenu: [
      {
        path: "/admin/customers/pendingtenders",
        title: "Pending Tender",
        moduleName: "enquiry",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/customers/releasedtenders",
        title: "Released Tender",
        moduleName: "enquiry",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Installers",
    moduleName: "installers",
    iconType: "material-icons-two-tone",
    icon: "manage_accounts",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["SuperAdministrator"],
    submenu: [
      {
        path: "/admin/installers/pendingapprovals",
        title: "Pending Approvals",
        moduleName: "installers",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/installers/approvedinstallers",
        title: "Approved Installers",
        moduleName: "installers",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "/admin/blogs/allblogs",
    title: "Blogs",
    moduleName: "blogs",
    iconType: "material-icons-two-tone",
    icon: "rss_feed",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["SuperAdministrator"],
    submenu: [],
  },
  {
    path: "/admin/testimonials/alltestimonials",
    title: "Testimonials",
    moduleName: "testimonials",
    iconType: "material-icons-two-tone",
    icon: "interpreter_mode",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["SuperAdministrator"],
    submenu: [],
  },
  {
    path: "/admin/projects/allprojects",
    title: "Projects",
    moduleName: "projects",
    iconType: "material-icons-two-tone",
    icon: "rocket_launch",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["SuperAdministrator"],
    submenu: [],
  },
  {
    path: "",
    title: "Report",
    moduleName: "report",
    iconType: "material-icons-two-tone",
    icon: "flag",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["SuperAdministrator"],
    submenu: [
      {
        path: "reports/tender-report",
        title: "Tender Report",
        moduleName: "report",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "reports/review-report",
        title: "Review Report",
        moduleName: "report",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },

  // Installer Module
  {
    path: "/installer/dashboard",
    title: "Dashboard",
    moduleName: "installer",
    iconType: "material-icons-two-tone",
    icon: "dashboard",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["Installer"],
    submenu: [],
  },
  {
    path: "/installer/opentender",
    title: "Open Tender",
    moduleName: "installer",
    iconType: "material-icons-two-tone",
    icon: "file_open",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["Installer"],
    submenu: [],
  },
  {
    path: "/installer/closedtender",
    title: "Closed Tender",
    moduleName: "installer",
    iconType: "material-icons-two-tone",
    icon: "cancel_presentation",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["Installer"],
    submenu: [],
  },
  {
    path: "/installer/awardedtender",
    title: "Awarded Tender",
    moduleName: "installer",
    iconType: "material-icons-two-tone",
    icon: "military_tech",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["Installer"],
    submenu: [],
  },
  {
    path: "",
    title: "Report",
    moduleName: "report",
    iconType: "material-icons-two-tone",
    icon: "flag",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Installer"],
    submenu: [
      {
        path: "installer/profile-report",
        title: "Profile Report",
        moduleName: "report",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "installer/performance-report",
        title: "Performance Report",
        moduleName: "report",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  }
];
